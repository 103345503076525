import { useQuery } from '@graphcommerce/graphql'
import {FlagAvatar, extendableComponent, iconCustomerService, IconSvg, iconLanguage} from '@graphcommerce/next-ui'
import {SxProps, Theme, Link, ListItemIcon, ListItemText} from '@mui/material'
import { useRouter } from 'next/router'
import { StoreConfigDocument } from '@graphcommerce/magento-store/StoreConfig.gql'
import React from 'react'

export type StoreSwitcherButtonProps = { sx?: SxProps<Theme>, showLabel?: boolean, showFlag?: boolean, showIcon?: boolean }

const name = 'StoreSwitcherButton' as const
const parts = ['root', 'avatar'] as const
const { classes } = extendableComponent(name, parts)

export function StoreSwitcherLink(props: StoreSwitcherButtonProps) {
  const { sx, showLabel = false, showFlag = true, showIcon = false } = props
  const config = useQuery(StoreConfigDocument)
  const country = config.data?.storeConfig?.locale?.split('_')?.[1]?.toLowerCase() ?? ''
  const router = useRouter()

  return (
    <Link
      component='button'
      className={classes.root}
      underline='none'
      onClick={() => router.push('/switch-stores')}
      sx={
        [{
          textTransform: 'uppercase',
          color: 'text.primary',
          fontSize: '16px',
          display: 'flex',
          alignItems: 'center',
        }, ...(Array.isArray(sx) ? sx : [sx])]
      }
    >
      { showFlag &&
          <FlagAvatar
              country={country}
              className={classes.avatar}
              sx={{ height: 20, width: 20, marginRight: '10px' }}
          />
      }
      { showIcon &&
        <>
          <ListItemIcon sx={{ paddingRight: '8px', minWidth: 'unset' }}>
              <IconSvg src={iconLanguage} size='medium' />
          </ListItemIcon>
          <ListItemText>{config.data?.storeConfig?.store_name?.substring(0, 3)}</ListItemText>
        </>
      }
      { showLabel &&
        <>
          {config.data?.storeConfig?.store_name?.substring(0, 3)}
        </>
      }
    </Link>
  )
}
